.App {
  padding-top: 20px;
  padding-bottom: 20px;
  .title {
    margin-bottom: 16px;
    font-size: 40px;
    font-weight: bold;
  }

  .description {
    margin-bottom: 20px;
    .bold {
      font-size: 22px;
      font-weight: bold;
    }
  }

  .video {
    margin-bottom: 16px;
    position: relative;
    padding-bottom: 56.25%; /*アスペクト比 16:9の場合の縦幅*/
    height: 0;
    overflow: hidden;

    iframe {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }

  .photos {
    .photo.col {
      padding: 1px;
      img {
        width: 100%;
        vertical-align: bottom;
      }
    }
  }
}


